<!--
 * @Descripttion: 
 * @version: 1.0
 * @Author: horiz0n_Z
 * @Date: 2020-11-26 20:44:36
 * @LastEditors: horiz0n_Z
 * @LastEditTime: 2021-01-10 22:39:23
-->
<template>
  <div class="page-box">
    <div class="content-box content-box--form">
      <div class="form__title">工单id ：{{ workOrder.number }}</div>
      <div>
        <div class="form__row" data-title="联系人">
          {{ workOrder.contactPerson }}
        </div>
        <div class="form__row" data-title="联系电话">
          {{ workOrder.contactMobile }}
        </div>
        <div class="form__row" data-title="店铺名称">
          {{ workOrder.shopName }}
        </div>
        <div class="form__row" data-title="门店地址">
          {{ workOrder.address }}
        </div>
        <div class="form__row" data-title="品牌区域">
          {{ workOrder.brandAreaName }}
        </div>
      </div>
      <div class="form__comment">
        注：
        <br />
        1. 此费用明细单可作为报价单或收费单使用；
        <br />
        2. 接受此单将被视为自动认同对奕侨公司所负的严格保密义务；
        <br />
        3.
        此费用明细单作为报价单时，其有效期为5个工作日。逾期奕侨公司保留调整价格的权力
      </div>
    </div>

    <div
      class="content-box"
      v-for="(item, index) in serviceProject"
      :key="index"
    >
      <memo-box :title="item.name">
        <div
          class="arrow"
          :class="item.showDetail ? '' : 'arrow--close'"
          @click="showDetail(index)"
          slot="header"
        ></div>
        <div slot="body" class="price-content">
          <div v-show="item.showDetail">
            <div class="price__title">材料费用</div>
            <div class="price-form">
              <div
                v-for="(material, materialIndex) in item.material"
                :key="materialIndex"
              >
                <div class="price__row">
                  <div>名称</div>
                  <div class="price__text">{{ material.name }}</div>
                </div>
                <div class="price__row">
                  <div>数量</div>
                  <div class="price__text">{{ material.number }}</div>
                </div>
                <div class="price__row">
                  <div>单位</div>
                  <div class="price__text">{{ material.unit }}</div>
                </div>
                <div class="price__row">
                  <div>单价</div>
                  <div class="price__text price__text--price">
                    {{ material.unitPrice }}
                  </div>
                </div>
                <div class="price__row">
                  <div class="price__total">合计</div>
                  <div
                    class="price__text price__text--highlight price__text--price"
                  >
                    {{ material.amount }}
                  </div>
                </div>
              </div>
            </div>
            <div class="price__title">人工费</div>
            <div class="price-form">
              <div
                v-for="(artificial, artificialIndex) in item.artificial"
                :key="artificialIndex"
              >
                <div class="price__row">
                  <div>工人数</div>
                  <div class="price__text">{{ artificial.people }} 人</div>
                </div>
                <div class="price__row">
                  <div>工时</div>
                  <div class="price__text">{{ artificial.hour }} 小时</div>
                </div>
                <div class="price__row">
                  <div>工作时间段</div>
                  <div class="price__text">
                    {{ artificial.period === 0 ? "白工" : "夜工" }}
                  </div>
                </div>
                <div class="price__row">
                  <div class="price__total">合计</div>
                  <div
                    class="price__text price__text--highlight price__text--price"
                  >
                    {{ artificial.amount }}
                  </div>
                </div>
              </div>
            </div>
            <div class="price__title">其他费用</div>
            <div class="price-form">
              <div class="price__row">
                <div>市内交通</div>
                <div class="price__text">{{ item.other.traffic }} 元</div>
              </div>
              <div class="price__row">
                <div>出差差旅</div>
                <div class="price__text">{{ item.other.business }} 元</div>
              </div>
              <div class="price__row">
                <div>材料运输</div>
                <div class="price__text">
                  {{ item.other.materialTransportation }} 元
                </div>
              </div>
              <div class="price__row">
                <div>现场细算</div>
                <div class="price__text">{{ item.other.scene }} 元</div>
              </div>
              <div class="price__row">
                <div>施工保险</div>
                <div class="price__text">
                  {{ item.other.constructionInsurance }} 元
                </div>
              </div>
              <div class="price__row">
                <div>其他费用</div>
                <div class="price__text">{{ item.other.other }} 元</div>
              </div>
              <div class="price__row">
                <div class="price__total">合计</div>
                <div
                  class="price__text price__text--highlight price__text--price"
                >
                  {{ item.other.amount }}
                </div>
              </div>
            </div>
          </div>
          <div class="subtotal">
            <div class="subtotal__title">小计</div>
            <div class="subtotal__price">{{ item.amount }}</div>
          </div>
        </div>
      </memo-box>
    </div>

    <div class="content-box content-box--bottom">
      <div>
        <div class="form__row" data-title="分项合计">
          {{ quotationDetail.subOptionAmount }} 元
        </div>
        <div
          class="form__row"
          :data-title="'综合管理费(' + managementFeePercent + '%)'"
        >
          {{
            Math.floor(
              quotationDetail.subOptionAmount *
                (managementFeePercent / 100) *
                100
            ) / 100
          }}
          元
        </div>
        <div class="form__row" :data-title="'税金(' + taxesPercent + '%)'">
          {{
            Math.floor(
              quotationDetail.subOptionAmount * (taxesPercent / 100) * 100
            ) / 100
          }}
          元
        </div>
      </div>
      <div class="total">
        <div class="total__title">总额</div>
        <div class="total__price">{{ quotationDetail.amount }}</div>
      </div>
    </div>

    <div class="btns flex-x center between">
      <div class="btn" @click="turnDown">驳回</div>
      <div class="btn btn--confirm" @click="pass">通过</div>
    </div>

    <turn-down
      v-model="showTurnDown"
      @onsubmit="submitTurnDown"
      ref="turnDown"
    />
  </div>
</template>

<script>
import MemoBox from "@/components/MemoBox/MemoBox";
import { detail, confirm } from "../../../utils/api/quotation";
import turnDown from "../../../components/turnDown/turnDown";
import TurnDown from "../../../components/turnDown/turnDown.vue";
import { Toast } from "vant";
import { orderConfig } from "../../../utils/api/utils";
export default {
  components: {
    "memo-box": MemoBox,
    "turn-down": turnDown,
  },

  data() {
    return {
      workOrder: {}, // 工单详情
      quotationDetail: {}, // 报价单详情
      serviceProject: [], // 服务列表
      showTurnDown: false, // 显示驳回
      managementFeePercent: 0, // 管理费率
      taxesPercent: 0, // 税金费率
    };
  },

  created() {
    this.getDetail();
  },

  methods: {
    getDetail() {
      detail({
        id: this.$route.query.id,
      }).then((res) => {
        this.workOrder = res.data.data.work;
        this.quotationDetail = res.data.data.quotation;
        this.serviceProject = this.quotationDetail.serviceProject;
        this.managementFeePercent = this.quotationDetail.managementFee;
        this.taxesPercent = this.quotationDetail.taxes;
      });
    },

    // 显示详情
    showDetail(index) {
      let item = JSON.parse(JSON.stringify(this.serviceProject[index]));
      item.showDetail = !item.showDetail;
      this.$set(this.serviceProject, index, item);
    },

    // 驳回
    turnDown() {
      this.showTurnDown = true;
    },

    // 发送驳回
    submitTurnDown(e) {
      confirm({
        id: this.$route.query.id,
        review: 2,
        reason: e.detail.text,
      })
        .then((res) => {
          Toast.success({
            message: "驳回成功",
            onClose: function () {
              this.$router.back();
            }.bind(this),
          });
          this.showTurnDown = false;
          this.$refs.turnDown.clear();
        })
        .catch((res) => {
          Toast(res.data.msg);
        });
    },

    // 通过
    pass() {
      confirm({
        id: this.$route.query.id,
        review: 1,
      })
        .then((res) => {
          Toast.success({
            message: "通过成功",
            onClose: function () {
              this.$router.back();
            }.bind(this),
          });
        })
        .catch((res) => {
          Toast(res.data.msg);
        });
    },
  },
};
</script>

<style src="../../../assets/css/review/Offer.css" scoped>
</style>